//GTA-4 + Meta Pixel

import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true,
  debug: false  // Set to true while testing
};


//Initiate tracking codes
export const initGA = (measurementId) => {
  ReactGA.initialize(measurementId);
};

export const initPixel = (pixelId) => {
  ReactPixel.init(pixelId, undefined, options);
};



export const trackPageView = (pagePath, referrer = document.referrer) => {
  ReactGA.send({ hitType: 'pageview', page: pagePath, referrer: referrer }); //google
  ReactPixel.pageView(); //meta

};

export const trackEvent = (category, action, label, value = null) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};

export const trackCustomEvent = (eventName, data = null) => {
  ReactPixel.track(eventName, data);
};


// Specific tracking functions for your website features
export const trackMealPlanGeneration = (planType = 'default') => {
  trackEvent(
    'Engagement',
    'generate_meal_plan',
    planType
  );

  trackCustomEvent('GenerateMealPlan', {
    content_name: 'Meal Plan',
    content_category: 'MealPlanning',
    value: planType
  });


};

export const trackEmailSubmission = (success) => {
  trackEvent(
    'Conversion',
    'email_submission',
    success ? 'success' : 'failure'
  );
  if (success) {
   trackCustomEvent('Lead', {
    content_name: 'Email Signup',
    content_category: 'Conversion'
  });
 }
};

// Track user source/referrer
export const trackUserSource = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const utm_source = queryParams.get('utm_source');
  const utm_medium = queryParams.get('utm_medium');
  const utm_campaign = queryParams.get('utm_campaign');

  if (utm_source || utm_medium || utm_campaign) {
    trackEvent(
      'Traffic',
      'user_source',
      `source:${utm_source || 'direct'}/medium:${utm_medium || 'none'}/campaign:${utm_campaign || 'none'}`
    );
  }
};

// Track exit intent
export const initExitIntentTracking = () => {
  let mouseY = 0;
  let isExitTracked = false;

  document.addEventListener('mousemove', (e) => {
    mouseY = e.clientY;
  });

  document.addEventListener('mouseout', (e) => {
    if (
      e.clientY < 0 && // User's mouse leaves from the top of the page
      !isExitTracked && // Haven't tracked this exit yet
      document.documentElement.scrollTop > 100 // User has scrolled a bit
    ) {
      trackEvent(
        'Behavior',
        'exit_intent',
        window.location.pathname
      );
      isExitTracked = true;
      // Reset after 30 seconds
      setTimeout(() => {
        isExitTracked = false;
      }, 30000);
    }
  });
};