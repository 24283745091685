import React, { useState } from 'react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { TextField } from "@mui/material";
import { Button } from "antd";
import { Loader2 } from 'lucide-react';
import { createRoot } from 'react-dom/client';
import { trackEvent, trackEmailSubmission } from '../utils/analytics';

const EmailMealPlan = ({ dietType, calories, macros, mealPlan, mealPlans }) => (

  <div className="p-6 bg-white max-w-[600px] font-sans">
    <div className="bg-primary-50 p-4 rounded-lg mb-6">
      <h1 className="text-2xl font-bold text-center text-primary-800 mb-2">{dietType} Meal Plan</h1>
      <p className="text-center text-primary-700 font-bold">{calories} calories</p>
      <div className="flex justify-center gap-8 mt-4 text-primary-600">
        <p>Protein: {macros.protein}g</p>
        <p>Carbs: {macros.carbs}g</p>
        <p>Fat: {macros.fat}g</p>
      </div>
    </div>

    <div className="space-y-6">
      {mealPlan.map((meal, index) => (
        <div key={index} className="border-l-4 border-primary-400 bg-primary-50 p-4 rounded-r-lg">
          <h2 className="font-bold text-primary-800 text-lg mb-2">{meal.title}</h2>
          <div className="text-2xl mb-2">{meal.emojis.join(' ')}</div>
          <p className="text-primary-700 mb-1">{meal.description}</p>
          <p className="text-sm text-primary-600">{meal.macros}</p>
          <p className="text-xs text-primary-500 mt-1">{meal.micros}</p>
        </div>
      ))}
    </div>

    <div className="mt-6 text-center text-xs text-primary-600">
      Generated at {new Date().toLocaleDateString()}
    </div>
  </div>
);

const calcAvgMacros = (days) => {
    let totalProtein = 0;
    let totalCarbs = 0;
    let totalFat = 0;
    let totalDays = 0
    
    days.forEach(day => {
      //console.log("Day "+totalDays+1+"'s meals: "+day.meals);
      totalProtein += day.macros.protein;
      totalCarbs += day.macros.carbs;
      totalFat += day.macros.fat;   
      totalDays += 1;   
    })
    
    //console.log("total protein: "+totalProtein+" ,total carbs: "+totalCarbs+" ,total fat: "+totalFat+" ,totalDays: "+totalDays);
    return {
      protein: parseInt(totalProtein / totalDays) ,
      carbs: parseInt(totalCarbs / totalDays) ,
      fat: parseInt(totalFat / totalDays) 
    };

  }

const GridMealPlan = ({ dietType, calories, macros, mealPlans }) => {
  const contentRef = useRef(null);
  const avgMacros = calcAvgMacros (mealPlans);


  return (
    <div className="w-full flex justify-center">
      <div ref={contentRef} style={{ width: '1200px' }} className="bg-white p-8 font-sans">
        {/* Header Section */}
        <div className="bg-primary-50 p-4 rounded-lg mb-6">
          <h1 className="text-3xl font-bold text-center text-primary-800 mb-2">{dietType} Meal Plan</h1>
          <p className="text-xl text-center text-primary-700 font-bold">{calories} calories</p>
          <h3 className="text-sm font-bold text-center text-primary-600 mt-3">Macronutrients - Daily Averages</h3>
          <div className="flex justify-center gap-8 text-lg text-primary-600">
            <p>Protein: {avgMacros.protein}g</p>
            <p>Carbs: {avgMacros.carbs}g</p>
            <p>Fat: {avgMacros.fat}g</p>
          </div>
        </div>

        {/* Days as Rows */}
        <div className="space-y-4">
          {mealPlans.map((day, dayIndex) => (
            <div key={dayIndex} className="bg-primary-50 p-4 rounded-lg">
              <h2 className="text-xl font-bold text-primary-800 mb-4">Day {dayIndex + 1}</h2>
              <div className="grid grid-cols-5 gap-4">
                {day.meals.map((meal, mealIndex) => (
                  <div 
                    key={mealIndex} 
                    className="border-l-4 border-primary-400 bg-white p-3 rounded-lg">
                    <h3 className="font-bold text-primary-800 text-base mb-1">{meal.title}</h3>
                    <div className="text-xl mb-1">{meal.emojis.join(' ')}</div>
                    <p className="text-primary-700 text-sm mb-1">{meal.description}</p>
                    <p className="text-xs text-primary-600">{meal.macros}</p>
                    <p className="text-xs text-primary-500">{meal.micros}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
);};


export const SendImageEmail = ({selectedDiet, calories, macros, mealPlan, mealPlans, validDays }) => {
  const navigate = useNavigate();
  const IMGBB_apiKey = process.env.REACT_APP_IMGBB_API_KEY;
  const [email, setEmail] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [shareableLink, setShareableLink] = useState('');

  let buttonText ;
  let ctaTitle ; 
  if (validDays > 1) {
    ctaTitle = "Want More?"
    buttonText = "Send My "+validDays+" Days Plan";
  }else {
    ctaTitle = "Save Your Plan"
    buttonText = "Save "
  }

  const sendEmailWithLink = async (linkToSend) => {
    try {
      const sendResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/send-meal-plan-link`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          mealPlanLink: linkToSend,
          dietType: selectedDiet,
          calories
        }),
      });
        
      if (!sendResponse.ok) {
        throw new Error('Failed to send email');
        trackEmailSubmission(false);
      };
      trackEmailSubmission(true); 

      navigate('/thank-you', { 
        state: { 
          email,
          dietType: selectedDiet 
        }
      });
    } catch (error) {
      trackEmailSubmission(false);
      setErrorMessage('Something went wrong :( try again.');
      console.error('Error sending email:', error);
    } finally {
      setIsSending(false);
    }
  };
  
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage('');
  };

  const handleSendEmail = async () => {
    if (!email) return;
    
    try {
      setIsSending(true);       
      trackEvent('User', 'Click', 'Send Plan to Email');
      setErrorMessage('');

      const container = document.createElement('div');
      container.style.padding = '20px';
      container.style.backgroundColor = 'white';
      document.body.appendChild(container);
    
      const emailRoot = createRoot(container);
      
      emailRoot.render(
        <GridMealPlan 
          dietType={selectedDiet}
          calories={calories}
          macros={macros}
          mealPlan={mealPlan}
          mealPlans={mealPlans}
        />
      );
    
      await new Promise(resolve => setTimeout(resolve, 2000));
    
      const canvas = await html2canvas(container, {
        logging: true,
        backgroundColor: null,
        scale: 1,
        windowWidth: 1200,
        windowHeight: container.offsetHeight
      });
      
      document.body.removeChild(container);
      emailRoot.unmount();

      const imageData = canvas.toDataURL('image/jpeg', 0.8);
      const base64Image = imageData.split(',')[1]; 
    
      const formData = new FormData();
      formData.append('image', base64Image);
        
      const response = await fetch('https://api.imgbb.com/1/upload?key=' + IMGBB_apiKey, {
        method: 'POST',
        body: formData
      });
        
      const data = await response.json();
      if (data.success) {
        console.log("image uploaded! "+data.data.url);
        setShareableLink(data.data.url);
        sendEmailWithLink(data.data.url);
      } else {
        console.error('Upload failed:', data.error);
      }

    } catch (error) {
      setErrorMessage('Something went wrong :( try again.');
      console.error('Error sending email:', error);
    } finally {
      setIsSending(false);
    }
  };

  return (  
    <div className="mb-8 p-4 bg-secondary-100 rounded-md border-l-4 border-secondary-800 shadow-sm">
      <h4 className="text-lg font-semibold text-primary-800 mb-4">{ctaTitle}</h4>
      <TextField
        sx={{
          backgroundColor: 'white',
          input: {
            color: 'black !important',
            backgroundColor: 'white !important'
          },
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'white !important',
            '& fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)'
            },
            '&.Mui-focused fieldset': {
              borderColor: '#001c14 !important'
            }
          },
          '& .MuiInputLabel-root': {
            color: 'rgba(0, 0, 0, 0.6)'
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#001c14'
          }
        }}
        label="Email Address"
        variant="outlined"
        value={email}
        onChange={handleEmailChange}
        fullWidth
        className="mb-4"
      />
      <Button
        onClick={handleSendEmail}
        disabled={isSending || !email}
        variant="contained"
        fullWidth
        className="mt-4 bg-secondary-700 text-secondary-100 font-semibold border-secondary-400"
      >
        {isSending ? (
          <>
            <Loader2 className="w-4 h-4 mr-2 animate-spin" />
            Sending...
          </>
        ) : (
          buttonText
        )}
      </Button>
      {errorMessage && <p className="text-red-600 mt-2">{errorMessage}</p>}
    </div>
  );
};
