import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const TOS = () => {
  const location = useLocation();


  return (
    <>
      <Helmet>
        <title> Terms of Service| Seymore App </title>
      </Helmet>
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-secondary-100 to-secondary-200 p-4">
        <div className="w-full max-w-[800px] space-y-8 bg-white rounded-2xl shadow-xl p-8 animate-fade-up">
         <div className="text-left">
            

          {/* Title */}
            <h1 className="text-3xl font-bold text-primary-500 mb-2">
              Terms &amp; Conditions
            </h1>
            <h4 className="text-sm font-bold text-primary-800 mb-6">
              Last Updated: December 15, 2024
            </h4>
            {/* Message */}
            <div className="space-y-4">
            
                  <p className="font-bold text-lg text-gray-800">
                  1. Acceptance of Terms
                  </p>

                  <p className="text-gray-600 text">
                  By accessing and using Seymore meal planning app ('Service'), 
                  you agree to be bound by these Terms and Conditions. 
                  If you do not agree with any part of these terms,
                  please do not use our Service.
                  </p>
                  

                  <p className="font-bold text-lg text-gray-800">
                  2. Use of Service
                  </p>

                  <p className="font-bold text-gray-600">
                  2.1 Nutritional Information Disclaimer
                  </p>

                  <p className="text-gray-600">
                  The nutritional information and meal plans provided through our Service are for informational purposes only. 
                  While we strive to provide accurate information, we make no representations or warranties about the accuracy, 
                  completeness, or suitability of this information. Use of nutritional information and meal plans is at your own risk.
                  </p>

                  <p className="font-bold text-gray-600">
                  2.2 Health Disclaimer
                  </p>

                  <p className="text-gray-600">
                  <ul>
                    <li className="list-disc ml-6 mb-2"> You acknowledge that meal plans and nutritional advice should not replace professional medical advice</li>
                    <li className="list-disc ml-6 mb-2"> Before starting any diet or exercise program, consult with qualified healthcare professionals</li>
                    <li className="list-disc ml-6"> We are not responsible for any health problems that may result from meal plans, recipes, or advice accessed through our Service</li>
                  </ul>
                  </p>

                  <p className="font-bold text-gray-600">
                  2.3 Results Disclaimer
                  </p>

                  <p className="text-gray-600">
                  We do not guarantee any specific results from using our meal plans or following our nutritional guidance. 
                  Individual results may vary based on numerous factors outside our control.
                  </p>

                  <p className="font-bold text-lg text-gray-800">
                  3. User Responsibilities
                  </p>

                  <p className="text-gray-600">
                  Users agree to:
                  <ul className="mt-4">
                    <li className="list-disc ml-6 mb-2"> Provide accurate information when using the Service</li>
                    <li className="list-disc ml-6 mb-2"> Use the Service in compliance with applicable laws and regulations</li>
                    <li className="list-disc ml-6 mb-2"> Not share account credentials with third parties</li>
                    <li className="list-disc ml-6"> Report any errors or issues found in meal plans or nutritional information</li>
                  </ul>
                  </p>

                  <p className="font-bold text-lg text-gray-800">
                  4. Limitation of Liability
                  </p>

                  <p className="text-gray-600">
                  To the maximum extent permitted by law, we shall not be liable for:
                  <ul className="mt-4">
                    <li className="list-disc ml-6 mb-2"> Any direct, indirect, incidental, special, or consequential damages</li>
                    <li className="list-disc ml-6 mb-2"> Health issues, allergic reactions, or other medical conditions resulting from use of our meal plans</li>
                    <li className="list-disc ml-6 mb-2"> Inaccuracies in nutritional information or meal plans</li>
                    <li className="list-disc ml-6"> Personal injury or dietary issues related to following our meal plans</li>
                  </ul>
                  </p>

                  <p className="font-bold text-lg text-gray-800">
                  5. Intellectual Property
                  </p>
                  <p className="text-gray-600">
                  All content on this website, including but not limited to meal plans, recipes, 
                  and nutritional information, is protected by copyright and other intellectual property rights.
                  </p>

                  <h2 className="text-xl font-bold text-primary-500 mb-2">
                      Privacy Policy
                  </h2>

                  <p className="font-bold text-lg text-gray-800">
                  1. Information We May Collect
                  </p>

                  <p className="font-bold text-gray-600">
                  1.1 Personal Information
                  </p>

                  <p className="text-gray-600">
                    <ul>
                      <li className="list-disc ml-6 mb-2"> Name and contact information</li>
                      <li className="list-disc ml-6 mb-2"> Height, weight, gender, and dietary preferences</li>
                      <li className="list-disc ml-6 mb-2"> Account credentials</li>
                      <li className="list-disc ml-6"> Payment information</li>
                    </ul>
                  </p>

                  <p className="font-bold text-gray-600">
                  1.2 Usage Information
                  </p>
                  <p className="text-gray-600">
                    <ul>
                      <li className="list-disc ml-6 mb-2"> Meal plan selections and preferences</li>
                      <li className="list-disc ml-6 mb-2"> Website usage patterns</li>
                      <li className="list-disc ml-6 mb-2"> Device and browser information</li>
                    </ul>
                  </p>
                  

                  <p className="font-bold text-lg text-gray-800">
                  2. How We Might Use Your Information
                  </p>
                  
                  <p className="text-gray-600">
                  We use collected information to:
                  <ul className="mt-4">
                    <li className="list-disc ml-6 mb-2"> Create and manage your account</li>
                    <li className="list-disc ml-6 mb-2"> Provide personalized meal plans</li>
                    <li className="list-disc ml-6 mb-2"> Process payments</li>
                    <li className="list-disc ml-6 mb-2"> Improve our Service</li>
                    <li className="list-disc ml-6"> Send important updates and notifications</li>
                  </ul>
                  </p>

                  <p className="font-bold text-lg text-gray-800">
                  3. Information Sharing
                  </p>

                  <p className="text-gray-600">
                  We do not sell your personal information. We may share information with:
                  <ul className="mt-4">
                    <li className="list-disc ml-6 mb-2"> Service providers who assist in our operations</li>
                    <li className="list-disc ml-6 mb-2"> Legal authorities when required by law</li>
                    <li className="list-disc ml-6"> Third parties with your explicit consent</li>
                  </ul>
                  </p>

                  <p className="font-bold text-lg text-gray-800">
                  4. Data Security
                  </p>

                  <p className="text-gray-600">
                  We implement reasonable security measures to protect your information. However, 
                  no internet transmission is completely secure, and we cannot guarantee absolute security.
                  </p>


                  <p className="font-bold text-lg text-gray-800">
                  5. Your Rights
                  </p>

                  <p className="text-gray-600">
                  You have the right to:
                  <ul className="mt-4">
                      <li className="list-disc ml-6 mb-2"> Access your personal information</li>
                      <li className="list-disc ml-6 mb-2"> Correct inaccurate information</li>
                      <li className="list-disc ml-6 mb-2"> Request deletion of your information</li>
                      <li className="list-disc ml-6"> Opt-out of marketing communications</li>
                    </ul>
                  </p>

                  <p className="font-bold text-lg text-gray-800">
                  6. Cookies
                  </p>

                  <p className="text-gray-600">
                   We use cookies to:
                  <ul className="mt-4">
                      <li className="list-disc ml-6 mb-2"> Maintain your session</li>
                      <li className="list-disc ml-6 mb-2"> Remember your preferences</li>
                      <li className="list-disc ml-6 mb-2"> Analyze website usage</li>
                      <li className="list-disc ml-6"> Improve user experience</li>
                    </ul>
                  </p>
                  <p className="font-bold text-lg text-gray-800">
                   7. GDPR Rights for EU Residents
                  </p>

                  <p className="text-gray-600">
                  If you are a resident of the European Union (EU) or European Economic Area (EEA), 
                  you have certain data protection rights under the General Data Protection Regulation (GDPR). These include:
                  </p>

                   <p className="font-bold text-gray-600">
                  7.1 Your Enhanced Privacy Rights
                  </p>

                  <ul className="mt-4">
                      <li className="list-disc ml-6 mb-2"> Right to Access: Request copies of your personal data</li>
                      <li className="list-disc ml-6 mb-2"> Right to Rectification: Correct inaccurate or incomplete information</li>
                      <li className="list-disc ml-6 mb-2"> Right to Erasure: Request deletion of your personal data ("right to be forgotten")</li>
                      <li className="list-disc ml-6 mb-2"> Right to Restrict Processing: Limit how we use your personal data</li>
                      <li className="list-disc ml-6 mb-2"> Right to Data Portability: Receive your data in a structured, commonly used format</li>
                      <li className="list-disc ml-6 mb-2"> Right to Object: Object to our processing of your personal data</li>
                      <li className="list-disc ml-6"> Rights Related to Automated Decision Making: Challenge and request human intervention in automated decisions</li>
                    </ul>

                  <p className="font-bold text-gray-600">
                  7.2 Legal Basis for Processing
                  </p>

                  <p className="text-gray-600">
                  We process your personal data under the following legal bases:
                  <ul className="mt-4">
                      <li className="list-disc ml-6 mb-2"> Consent: When you explicitly agree to the processing</li>
                      <li className="list-disc ml-6 mb-2"> Contract: When processing is necessary to fulfill our service agreement</li>
                      <li className="list-disc ml-6 mb-2"> Legal Obligation: When required by EU or member state law</li>
                      <li className="list-disc ml-6"> Legitimate Interests: When we have a justified business interest that doesn't override your rights</li>
                    </ul>
                  </p>

                  <p className="font-bold text-gray-600">
                 7.3 Data Transfers Outside the EU
                  </p>

                  <p className="text-gray-600">
                  When we transfer your data outside the EU, we ensure adequate protection through:
                  <ul className="mt-4">
                      <li className="list-disc ml-6 mb-2"> EU Standard Contractual Clauses</li>
                      <li className="list-disc ml-6 mb-2"> Adequacy decisions by the European Commission</li>
                      <li className="list-disc ml-6"> Other appropriate safeguards as required by GDPR</li>
                    </ul>
                  </p>

                  <p className="font-bold text-gray-600">
                  7.4 Exercise Your Rights
                  </p>

                  <p className="text-gray-600">
                  When we transfer your data outside the EU, we ensure adequate protection through:
                  <ul className="mt-4">
                      <li className="list-disc ml-6 mb-2"> Email us at feedme@seymoreapp.ai</li>
                    </ul>
                  </p>



                   <p className="text-gray-600">
                   We will respond to all requests within 30 days.
                   </p>


                  <p className="font-bold text-lg text-gray-800">
                  8. Changes to Privacy Policy
                  </p>

                  <p className="text-gray-600">
                  We reserve the right to modify this privacy policy at any time. 
                  Changes will be effective immediately upon posting to the website.
                  </p>

                  <p className="font-bold text-lg text-gray-800">
                  9. Contact Us
                  </p>

                  <p className="text-gray-600">
                  For questions about these policies, contact us at feedme@seymoreapp.ai</p>
            </div>

            {/* Back Button */}
            <Link 
              to="/"
              className="mt-8 inline-block px-8 py-3 bg-yellow-500 text-white font-semibold rounded-lg 
                       shadow-md hover:bg-yellow-600 transform hover:-translate-y-0.5 transition-all 
                       duration-150 ease-in-out active:shadow-sm active:translate-y-0"
            >
              Back to Home
            </Link>

            
          </div>
        </div>
      </div>
    </>
  );
};