/*for testing */
const badResponse = `Here's a 4-day meal plan with 5 meals a day for a Balanced Diet totaling approximately 1000 calories per day, formatted as a JSON array:
[
  {
    "Day 1": {
      "Breakfast": {
        "title": "Oatmeal with Berries",
        "emojis": ["🥣", "🫐"],
        "description": "1/2 cup oatmeal with 1/2 cup mixed berries",
        "macros": "200 cal, 5g protein, 40g carbs, 3g fat",
        "micros": "Fiber, Vitamin C, Antioxidants"
      },
      "Morning Snack": {
        "title": "Hard-boiled Egg",
        "emojis": ["🥚"],
        "description": "1 hard-boiled egg",
        "macros": "70 cal, 6g protein, 0g carbs, 5g fat",
        "micros": "Vitamin D, Choline, Selenium"
      },
      "Lunch": {
        "title": "Tuna Salad Wrap",
        "emojis": ["🌯", "🥗"],
        "description": "1 small whole-wheat wrap with 1/4 cup tuna salad and lettuce",
        "macros": "250 cal, 20g protein, 25g carbs, 8g fat",
        "micros": "Omega-3, Vitamin K, Folate"
      },
      "Afternoon Snack": {
        "title": "Yogurt with Almonds",
        "emojis": ["🥛", "🥜"],
        "description": "1/2 cup low-fat yogurt with 1 oz almonds",
        "macros": "180 cal, 10g protein, 15g carbs, 10g fat",
        "micros": "Calcium, Vitamin E, Magnesium"
      },
      "Dinner": {
        "title": "Grilled Chicken with Vegetables",
        "emojis": ["🍗", "🥦", "🍅"],
        "description": "3 oz grilled chicken breast with 1 cup steamed broccoli and 1/2 cup cherry tomatoes",
        "macros": "300 cal, 30g protein, 20g carbs, 10g fat",
        "micros": "Vitamin C, Vitamin K, Potassium"
      }
    }
  },
  {
    "Day 2": {
      "Breakfast": {
        "title": "Scrambled Eggs with Spinach",
        "emojis": ["🍳", "🥬"],
        "description": "2 scrambled eggs with 1/2 cup sautéed spinach",
        "macros": "200 cal, 15g protein, 5g carbs, 12g fat",
        "micros": "Vitamin A, Iron, Folate"
      },
      "Morning Snack": {
        "title": "Apple with Peanut Butter",
        "emojis": ["🍎", "🥜"],
        "description": "1 small apple with 1 tbsp peanut butter",
        "macros": "150 cal, 4g protein, 20g carbs, 8g fat",
        "micros": "Fiber, Vitamin C, Vitamin E"
      },
      "Lunch": {
        "title": "Quinoa Salad",
        "emojis": ["🥗", "🫘"],
        "description": "1/2 cup cooked quinoa with 1/4 cup black beans, 1/4 avocado, and mixed greens",
        "macros": "300 cal, 12g protein, 40g carbs, 12g fat",
        "micros": "Fiber, Iron, Magnesium"
      },
      "Afternoon Snack": {
        "title": "`


function cleanJson(jsonStr) {
  const jsonStartIndex = jsonStr.indexOf('[');
   if (jsonStartIndex === -1) {
      throw new Error('Response does not contain valid JSON');
    }

    const result = jsonStr.substring(jsonStartIndex);

    return(result);
}

function repairTruncatedJSON(jsonStr) {
  // First, clean up any control characters that might cause issues
  let cleaned = jsonStr.replace(/[\x00-\x1F\x7F]/g, '');
  console.log("json w/o control chars "+cleaned);
  console.log("*********************");
  
  let inString = false;
  let stringStart = -1;
  let lastValidChar = 0;
  let openBraces = 0;
  let closeBraces = 0;
  let openBrackets = 0;
  let closeBrackets = 0;
  let colonCount = 0;
  let commaCount = 0;
  let lastPropertyStart = -1;
  let lastPropertyEnd = -1;
  let lastValueStart = -1;
  let lastCompleteObjectEnd = -1;
  let stack = [];

  // Process string character by character
  for (let i = 0; i < cleaned.length; i++) {
    const char = cleaned[i];
    //console.log(i+" "+char);

    // Handle string detection
    if (char === '"' && cleaned[i - 1] !== '\\') {
      if (!inString) {
        stringStart = i;
      } else {
        // End of a string - could be property name or value
        if (lastPropertyStart === -1) {
          lastPropertyStart = stringStart;
          lastPropertyEnd = i;
        } else {
          lastValueStart = stringStart;
        }
      }
      inString = !inString;
      lastValidChar = i;
      continue;
    }
    
   if (!inString) {
      if (char === '{') {
        openBraces++;
        stack.push({ char:'{', position:i });
        lastValidChar = i;
      } else if (char === '}') {
        closeBraces++;
        if (stack[stack.length - 1].char === '{') {
          stack.pop();
          lastCompleteObjectEnd = i;
          console.log("object complete in place "+i);
        }
        lastValidChar = i;
      } else if (char === '[') {
        openBrackets++;
        stack.push({char:'[', position:i});
        lastValidChar = i;
      } else if (char === ']') {
        closeBrackets++;
        if (stack[stack.length - 1].char === '[') {
          stack.pop();
          lastCompleteObjectEnd = i;
          console.log("object complete in place "+i);
        }
        lastValidChar = i;
      } else if (!/\s/.test(char)) { // Non-whitespace character
        lastValidChar = i;
      }
    }
  }
  
  

   if (lastCompleteObjectEnd > -1) {
    console.log ("last completed object end is at "+lastCompleteObjectEnd);
    let result = cleaned.slice(0, lastCompleteObjectEnd + 1);

    // Clean up the stack - remove entries that came after our cut-off point
    stack = stack.filter(item => item.position <= lastCompleteObjectEnd);
    
    // Add necessary closing brackets/braces
    while (stack.length > 0) {
      const item = stack.pop();
      if (item.char === '{') result += '}';
      if (item.char === '[') result += ']';
    }

    console.log("this is json after cleaning is complete");
   console.log(result);
   console.log("++++++++++++++++++++++++++++++++++++++++++");
    
    try {
      // Validate the result
      JSON.parse(result);
      return result;
    } catch (e) {
      // If parsing fails, try just up to the last complete object
      return cleaned.slice(0, lastCompleteObjectEnd + 1);
    }
  }

}

function repairTruncatedJSONBACKUP(jsonStr) {
  console.log("starting repair process...")
  console.log("sick json: "+jsonStr);
  // Count opening and closing brackets/braces
  let openBraces = 0;
  let closeBraces = 0;
  let openBrackets = 0;
  let closeBrackets = 0;
  let quotesOpen = false;

  // Process string character by character
  for (let i = 0; i < jsonStr.length; i++) {
    const char = jsonStr[i];
    console.log (i+","+char);
    
    // Skip counting brackets inside quotes
    if (char === '"' && jsonStr[i - 1] !== '\\') {
      quotesOpen = !quotesOpen;
      continue;
    }
    if (quotesOpen) continue;

    // Count brackets
    if (char === '{') openBraces++;
    if (char === '}') closeBraces++;
    if (char === '[') openBrackets++;
    if (char === ']') closeBrackets++;

  }

  //remove white space and non-printable chars 
  while (jsonStr.slice(-1).charCodeAt(0) <= 32) {
    jsonStr = jsonStr.slice(0,-1); 
  }

  // Add missing closing brackets/braces
  if (quotesOpen) {
    //if there's an uneven number of quotes, then the last char is a dangling quote, need to be removed
    jsonStr = jsonStr.slice(0,-1);
    console.log("removed dangling quote "+jsonStr);

    } else {
    console.log(" no dangling quote");
  }

  //remove white space and non-printable chars 
  while (jsonStr.slice(-1).charCodeAt(0) <= 32) {
    jsonStr = jsonStr.slice(0,-1); 
  }
   
  

  if (jsonStr.lastIndexOf(",") == jsonStr.length-1) {
    //dangling comma
    jsonStr = jsonStr.slice(0,-1);
     console.log("removed dangling comma "+jsonStr);
  } else {
    console.log("no dangling comma");

  }
  let repairedJson = jsonStr;
  while (closeBraces < openBraces) {
    repairedJson += '}';
    closeBraces++;
  }
  while (closeBrackets < openBrackets) {
    repairedJson += ']';
    closeBrackets++;
  }

  return repairedJson;
}



function extractMealPlanData(jsonResponse) {
  // Initialize result object
  const extractedData = [];
  let validDaysCount = 0;

  // first, let's remove everything before the start of the json
  const cleanedJson = cleanJson(jsonResponse);
  console.log("clean json "+cleanedJson);

  try {
    // If input is a string, try to repair it if needed
    let data;
    if (typeof cleanedJson === 'string') {
      const repairedJson = repairTruncatedJSON(cleanedJson);
      console.log("reapaired json "+repairedJson);
      try {
        data = JSON.parse(repairedJson);
      } catch (e) {
        // If parsing fails even after repair, try to extract partial data
        console.log("json is still sick, trying somethig else");
        const truncatedMatch = repairedJson.match(/"Day \d+":\s*{[^}]*}/g);
        if (truncatedMatch) {
          // Construct a valid JSON object from partial matches
          data = {};
          truncatedMatch.forEach(dayMatch => {
            try {
              const dayObj = JSON.parse(`{${dayMatch}}`);
              Object.assign(data, dayObj);
            } catch (e) {
              // Skip invalid day matches
            }
          });
        } else {
          throw new Error('Unable to extract any valid data');
        }
      }
    } else {
      data = cleanedJson;
    }

    console.log("this is the data I'm working with "+data);
    
    // Expected meal times for validation
    const expectedMealTimes = ['Breakfast', 'Morning Snack', 'Lunch', 'Afternoon Snack', 'Dinner'];
    
    console.log(expectedMealTimes);
    // Expected properties for each meal
    const expectedMealProps = ['title', 'emojis', 'description', 'macros', 'micros'];
    console.log(expectedMealProps);


    // Iterate through days
    for (let i = 1; i <= 4; i++) {
      const dayKey = `Day ${i}`;
     
      
      if (!data[i-1]) {
        break; // Stop if we don't have this day's data
      }

      console.log ("I got to day "+dayKey);
      console.log ("this is its data: "+data[i-1][dayKey]);

      // Validate day structure
      const dayData = data[i-1][dayKey];
      let isValidDay = true;
      const validatedDayData = {};

      // Check each meal time
      for (const mealTime of expectedMealTimes) {
        if (!dayData[mealTime]) {
          console.log("meal "+mealTime+" of day "+dayKey+" doesn't exist");
          continue; // Skip invalid meal times but keep processing others
        }

        // Validate meal properties
        const meal = dayData[mealTime];
        let isValidMeal = true;
        const validatedMeal = {};

        // Check and sanitize each property
        for (const prop of expectedMealProps) {
          if (!meal[prop]) {
            isValidMeal = false;
            break;
          }

          // Type-specific validation and sanitization
          switch (prop) {
            case 'emojis':
              validatedMeal[prop] = Array.isArray(meal[prop]) ? meal[prop] : [];
              break;
            case 'macros':
            case 'micros':
            case 'title':
            case 'description':
              validatedMeal[prop] = String(meal[prop]);
              break;
            default:
              validatedMeal[prop] = meal[prop];
          }
        }

        if (isValidMeal) {
          console.log("meal "+mealTime+" of day "+dayKey+" is valid");
          //console.log(validatedMeal);
          validatedDayData[mealTime] = validatedMeal;
        }
      }

      // If we have any valid meals in this day, include it
      if (Object.keys(validatedDayData).length > 0) {
        const newDay = {[dayKey]:validatedDayData};
        extractedData[i-1] = newDay;
        //console.log(dayKey+":"+JSON.stringify(validatedDayData, null, 2));
        validDaysCount++;
      }
    }
      console.log(JSON.stringify(extractedData, null, 2));
      /*extractedData.forEach(dayObj => {
        const k = Object.keys(dayObj)[0]; // gets "Day 1", "Day 2", etc.
        console.log(k, dayObj[k]);
      });*/ 


    return {
      success: validDaysCount > 0,
      data: extractedData,
      daysExtracted: validDaysCount,
      message: validDaysCount > 0 
        ? `Successfully extracted ${validDaysCount} days of meal plan data`
        : 'No valid meal plan data could be extracted',
      isPartial: validDaysCount < 4 // Indicate if we only got partial data
    };

  } catch (error) {
    return {
      success: false,
      data: {},
      daysExtracted: 0,
      message: `Error processing meal plan data: ${error.message}`,
      isPartial: true
    };
  }
}

export const validateMealPlan = (data) => {
  console.log(data);
  return(extractMealPlanData (data));
}