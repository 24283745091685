import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Card, CardContent, LinearProgress } from '@mui/material';
import { Select, MenuItem, InputLabel, Checkbox } from "@mui/material";
import { TextField } from "@mui/material";
import { Alert } from "@mui/material";
import { Button } from "antd";
import { Loader2 } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts';


import { Link } from 'react-router-dom';

import { initGA, trackEvent, trackMealPlanGeneration } from '../utils/analytics';
import { validateMealPlan } from '../utils/jsonValidator'

import { SendImageEmail } from './SendImageEmail'

import mealDataDay from './mealDataDay.json'; // For testing without API calls - 1 day
import mealData4Days from './mealData4Days.json'; // For testing without API calls - 4 days
import '../dist/output.css';
import logo from '../assets/images/logo.png';


const badResponse='';






/*********** UI COMPONENTS ****************/

const SummaryBox = ({ calories, protein, carbs, fat }) => (
  <div className="grid grid-cols-4 gap-4 p-4 mb-8 bg-primary-100 rounded-lg">
    <div className="text-center">
      <p className="value text-2xl font-bold text-primary">{calories}</p>
      <p className="label text-sm text-primary-600">Calories</p>
    </div>
    <div className="text-center">
      <p className="value text-2xl font-bold text-primary-800">{protein}g</p>
      <p className="label text-sm text-primary-600">Protein</p>
    </div>
    <div className="text-center">
      <p className="value text-2xl font-bold text-primary-800">{carbs}g</p>
      <p className="label text-sm text-primary-600">Carbs</p>
    </div>
    <div className="text-center">
      <p className="value text-2xl font-bold text-primary-800">{fat}g</p>
      <p className="label text-sm text-primary-600">Fat</p>
    </div>
  </div>
);



const MealRow = ({ mealType, title, emojis, description, macros, micros }) => {
  
  return(
  <div className="mb-8 p-4 bg-primary-100 rounded-md border-l-4 border-primary-400 shadow-sm">
    <h4 className="text-sm font-semibold text-primary-500">{mealType}</h4>
    <h3 className="text-lg font-semibold mb-2 text-primary-800">{title}</h3>
    <div className="flex flex-col gap-2">
      <div className="flex gap-4">
        {emojis.map((emoji, index) => (
          <span key={index} className="text-2xl">{emoji}</span>
        ))}
      </div>
      <div>
        <p className="text-sm text-primary-900">{description}</p>
        <p className="text-xs text-primary-700 mt-1">{macros}</p>
        <p className="text-xs text-primary-600">{micros}</p>
      </div>
    </div>
  </div>
);};


const NutrientCharts = ({ macros, mealCalories }) => {
  const macroData = [
    { name: 'Protein', value: macros.protein, color: '#FF4D8B' },
    { name: 'Carbs', value: macros.carbs, color: '#FF9ABD' },
    { name: 'Fat', value: macros.fat, color: '#B3003F' }
  ];
  
  return (
    <div className="grid grid-cols-1 gap-6 mb-8 bg-primary-600 p-20 rounded-md">
      <div className="h-64 mt-6">
        <h3 className="text-2xl font-bold mb-6 text-white">Your Daily Breakdown</h3>
        <p className="text-sm text-white mb-2  font-bold">Macros Distribution</p>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={macroData}
              dataKey="value"
              cx="50%"
              cy="50%"
              labelLine={false}
              label={({ name, value }) => `${name}: ${value}g`}
              outerRadius={80}
              //fill="#8884d8"
              
            >
              {macroData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Legend />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="h-64 mt-8 mb-8">
        <p className="text-sm text-white mb-2 mt-10 font-bold">Calories per Meal</p>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={mealCalories}>
            <XAxis dataKey="name" stroke="#FFEDCE" tick={{fontSize:10}} interval={0} textAnchor= "end" sclaeToFit="true" angle= "-20"  />
            <YAxis stroke="#FFE6EF" />
            <Tooltip />
            <Bar dataKey="calories" fill="#FF7BA9" /> 
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const CalorieInput = ({ value, onChange, error }) => (
  <div className="flex-1">
    <TextField
      id="calorie-input"
      type="text"
      inputMode="numeric"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Enter daily calories"
      className={`w-full ${error ? 'border-red-500' : ''}`}
    />
    {error && (
      <p className="text-red-500 text-xs mt-1">{error}</p>
    )}
  </div>
);

const DietSelector = ({ selectedDiet, onDietChange, calories, onCaloriesChange, onCheckboxChange, onGenerate, errors, isLoading, isChecked }) => (

  <div className="mb-6 justify-items-left">
    <div className="flex gap-4">
      <div className="flex-1">
        <InputLabel id="diet-select-label">Your Diet</InputLabel>
        <Select style={{minWidth:220}}
          labelId="diet-select-label"
          id="diet-select"
          value={selectedDiet} 
          label="Select Diet"
          onChange={(e) => onDietChange(e.target.value)}
          >
          <MenuItem value={"Balanced Diet"}>Regular Balanced Diet</MenuItem>
          <MenuItem value={"Keto"}>Ketogenic</MenuItem>
          <MenuItem value={"Paleo"}>Paleo</MenuItem>
          <MenuItem value={"Vegetarian"}>Vegetarian</MenuItem>
          <MenuItem value={"Vegan"}>Vegan</MenuItem>
        </Select>
      </div>
      <div>
        <InputLabel id="calorie-input-label">Daily Calories</InputLabel>
        <CalorieInput 
          labelId="calorie-input-label"
          value={calories}
          onChange={onCaloriesChange}
          error={errors.calories}
        />
      </div>
      </div>
      <div className="mt-3">
      <Button 
        onClick={onGenerate}
        className="bg-primary-600 !hover:bg-primary-700 text-white h-10 rounded-md inline-flex"
        disabled={Boolean(errors.diet || errors.calories || !isChecked)}
      >
        Generate Plan
      </Button>
      <p className="text-xs text-primary-600 mt-4 mb-4">
      <Checkbox 
      defaultChecked
      sx={{ '& .MuiSvgIcon-root': { fontSize: 16, color:'#007d59' } }}
      checked={isChecked}
      onChange={(e) => onCheckboxChange(e.target.checked)}
       />
        By generating a meal plan you express your agreement to our&nbsp; 
        <Link
            to="./terms-of-service"
            className="text-primary-600 underline hover:text-primary-400"
            target="_blank"
            rel="noopener noreferrer"
          >
          terms of service
          </Link>
        </p>
    </div>
    {(errors.diet || errors.calories) && (
      
      <Alert severity="error">
         Please fix the errors above before generating your meal plan.
      </Alert>
      
    )}
  </div>
);


/*********** DATA RETRIVALCOMPONENTS ****************/


/* GENERATE FROM API CALL */
const handleGenerateFromAPI = async (
  validateInputs, 
  selectedDiet,
  calories,
  setMacros,
  setMealPlan,
  setIsLoading, 
  setApiError, 
  setProgressInterval,
  setProgressMsg, 
  startProgress, 
  create4DaysPlan,
  calculateMacros,
  addMealTypes,
  finishProgress,
  progressInterval,
  setLoadProgress,
  trackEvent
  ) => {
    if (!validateInputs()) return;
    trackMealPlanGeneration('daily'); 

    setIsLoading(true);
    setApiError(null);
    setProgressMsg(0);
    startProgress(); // Start the progress simulation

    try {
      console.log(`${process.env.REACT_APP_API_URL}/api/generate-meal-plan`);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generate-meal-plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_SECRET,
        },
        body: JSON.stringify({
          dietType: selectedDiet,
          calories: Number(calories)
        }),
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }


    const NewMealPlanData = await response.json()
    console.log(NewMealPlanData);

    // meal plan data object contains the following information:
    // success: boolean, are there more than 0 valid days
    //  data: the meal plan data array [{"day 1": {{breakfast:...},{lunch:...}...},{"day 2":{...}}]
    //  daysExtracted: number of valid days
    //  message: if there are valid days: Successfully extracted ${validDaysCount} days of meal plan data` otherwise: 'No valid meal plan data could be extracted',
    //  isPartial: Boolean, if there are less than 4 days. 

    create4DaysPlan(NewMealPlanData);

    //extract day 1 meals
    const mealsArray = Object.values(NewMealPlanData.data[0]["Day 1"]); //day 1 meals
    const meals = addMealTypes(mealsArray); //add back meal types (breakfast, snack etc.)
    const newMacros = calculateMacros(meals);


     // On success, complete the progress
    console.log("need to finish progress")
    finishProgress(meals, newMacros); //quickly bring the progress interval to the end and show the plan
    
      
    } catch (error) {
      setApiError('Failed to generate meal plan. Please try again.');
      console.error('Error generating meal plan:', error);
    } finally {
      //clean up the interval
      if (progressInterval) {
        clearInterval(progressInterval.progress);
        clearInterval(progressInterval.message);
        setProgressInterval(null);
        setTimeout(() =>setLoadProgress(0),2000); // reset progress
      }
    }
};

  
  /* GENERATE FROM MOCK JSON */
  const handleGenerateFromMock = (
  validateInputs, 
  selectedDiet,
  calories,
  setMacros,
  setMealPlan,
  setIsLoading, 
  setApiError, 
  setProgressInterval,
  setProgressMsg, 
  startProgress, 
  create4DaysPlan,
  calculateMacros,
  addMealTypes,
  finishProgress,
  progressInterval,
  setLoadProgress,
  trackEvent) => {
      if (!validateInputs()) return;

      trackMealPlanGeneration('daily');


      setIsLoading(true);
      setApiError(null);


      try {
        // Filter or adjust the meal data based on selectedDiet or calories
        //const filteredMeals = mealData; // Add filtering logic here if needed
      
      //save all the data to send via email
      //load json response
      
      const cleanData = validateMealPlan(badResponse);

      create4DaysPlan(cleanData)  

      //parse the first day data to show on screen
      const NewMealPlan = cleanData //cleanData.data; 
        
        //extract day 1 meals
      const mealsArray = Object.values(NewMealPlan[0]["Day 1"]); //day 1 meals
      const meals = addMealTypes(mealsArray); //add back meal types (breakfast, snack etc.)
      
        const newMacros = calculateMacros(meals);

        setMacros(newMacros);
        setMealPlan(meals);
      } catch (error) {
        setApiError('Failed to generate meal plan. Please try again.');
        console.error('Error generating meal plan:', error);
      } finally {
        setIsLoading(false);
      }
  };

//DEAL WITH DIFFERENT DATA SOURCES

const DATA_SOURCES = {
  API: 'api',
  STATIC: 'static',
  MOCK: 'mock'
};

// Then choose based on the environment variable
const getDataSource = () => {
  const source = process.env.REACT_APP_DATA_SOURCE;
  return DATA_SOURCES[source?.toUpperCase()] || DATA_SOURCES.MOCK;
};

// And have a map of functions
const handleGenerateMap = {
  [DATA_SOURCES.API]: handleGenerateFromAPI,
  //[DATA_SOURCES.STATIC]: handleGenerateFromStatic,
  [DATA_SOURCES.MOCK]: handleGenerateFromMock
};



/*********** MAIN COMPONENT ****************/

const NutritionPlan = () => {
  const [selectedDiet, setSelectedDiet] = useState('');
  const [calories, setCalories] = useState('');
  const [mealPlan, setMealPlan] = useState(null);
  const [fourDayPlan, setFourDayPlan] = useState(null);
  const [validDays, setValidDays] = useState(0);
  const [macros, setMacros] = useState({ protein: 0, carbs: 0, fat: 0 });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [email, setEmail] = useState('');
  const [LoadProgress, setLoadProgress] = useState(0);
  const [progressInterval, setProgressInterval] = useState(null);
  const [progressMsg, setProgressMsg] = useState(null);
  const [isChecked, setIsChecked] = useState(true);
  const dataSource = getDataSource();
  const handleGenerate = () => handleGenerateMap[dataSource](
  validateInputs, 
  selectedDiet,
  calories,
  setMacros,
  setMealPlan,
  setIsLoading, 
  setApiError, 
  setProgressInterval,
  setProgressMsg, 
  startProgress, 
  create4DaysPlan,
  calculateMacros,
  addMealTypes,
  finishProgress,
  progressInterval,
  setLoadProgress,
  trackEvent);


  const progressMessages =["Raiding the pantry...", "Searching for nutrients...", "Choosing fresh vegetables...", "Pondering protein...", "Calculating optimal snack distribution...", "Negotiating with the spice cabinet...", "Preparing breakfast...", "Rummaging in the cupboards looking for snacks...", "Coming up with lunch ideas...", "Calculating calories...", "Measuring portions...","Maximizing flavor...", "Polishing silverware...", "Folding napkins...", "Setting the table...","Lighting candles...","Dinner is almost done..."]

  
  const calculateMacros = (meals) => {
    let totalProtein = 0;
    let totalCarbs = 0;
    let totalFat = 0;

    meals.forEach(meal => {
      const [, p, c, f] = meal.macros.match(/\d+/g).map(Number);
      totalProtein += p;
      totalCarbs += c;
      totalFat += f;
    });

    return {
      protein: totalProtein,
      carbs: totalCarbs,
      fat: totalFat
    };
  };


  const handleDietChange = (value) => {
    setSelectedDiet(value);
    const newErrors = { ...errors };
    delete newErrors.diet;
    setErrors(newErrors);
  };

  const handleCaloriesChange = (value) => {
    setCalories(value);
    const newErrors = { ...errors };
    delete newErrors.calories;
    setErrors(newErrors);
  };

  const handleCheckboxChange = (value) => {
    setIsChecked(value);
  }


  const validateInputs = () => {
    const newErrors = {};

    if (!selectedDiet) {
      newErrors.diet = 'Please select a diet type';
    }

    if (!calories) {
      newErrors.calories = 'Please enter your daily calorie target';
    } else {
      const calorieNum = Number(calories);
      if (isNaN(calorieNum)) {
        newErrors.calories = 'Please enter a valid number';
      } else if (calorieNum < 1000) {
        newErrors.calories = 'Daily calories must be at least 1,000';
      } else if (calorieNum > 5000) {
        newErrors.calories = 'Daily calories must not exceed 5,000';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  //simulate progress bar progressions
  const startProgress = () => {
    const interval = setInterval(() => {
      setLoadProgress((prevProgress) => {
        // Only increase if less than 90%
        if (prevProgress < 90) {
          return prevProgress + 0.05;
        }
        return prevProgress;
      });
    }, 50); // Update every 50ms

    const msgInterval = setInterval(() => {
      setProgressMsg((prevMsg) => {
        //only change if not last message {
        if (prevMsg < progressMessages.length-1) {
         return prevMsg+1; 
        }

        return prevMsg;
      });
    }, 4500);

    setProgressInterval({ progress:interval, message:msgInterval});
};

const finishProgress = (meals, macros) => {
    const interval = setInterval(() => {
      setLoadProgress((prevProgress) => {
        // Only increase if less than 90%
        if (prevProgress < 100) {
          return prevProgress + 0.1;
        }
        showPlan(meals, macros)
        return prevProgress;
      });
    }, 5); // Update every 5ms
  setProgressInterval({ progress:interval});
};


  const showPlan = (meals, macros) => {
    setMacros(macros);
    setMealPlan(meals);

    setTimeout(() =>setIsLoading(false),1000); // give it a second to finish
  }

  const addMealTypes = (meals) =>{
    //add back the meals names as properties in the meals object
      const mealTypes = ["Breakfast", "Morning Snack","Lunch","Afternoon Snack","Dinner"];

      meals.forEach((meal, index) => {
        meal.mealType = mealTypes[index];
      });
      
    return(meals);
  }

  const create4DaysPlan = ( mealPlansData ) => {
    const mealPlans = mealPlansData.data; 
    console.log("meal plans "+mealPlans)
    const newMealPlans =[];
    const newValidDays = mealPlansData.daysExtracted //how many valid days we have
    mealPlans.forEach((day, index) => {
      
      const dayName = Object.keys(day);
      const newDayMeals = Object.values(day[dayName[0]]);
      const newDayMacros = calculateMacros(newDayMeals);
      const newDay = {meals: newDayMeals, macros: newDayMacros};
      //console.log(newDay["meals"]);
      //console.log("new day macros's: "+newDay.macros.protein+","+newDay.macros.carbs+","+newDay.macros.fat);
      newMealPlans[index] = newDay;
    });
     console.log(JSON.stringify(newMealPlans, null, 2));
    setFourDayPlan (newMealPlans);
    setValidDays (newValidDays);
  }

  


  const getMealCalories = () => {
    if (!mealPlan) return [];
    return mealPlan.map(meal => ({
      name: meal.title,
      calories: parseInt(meal.macros.match(/\d+/)[0])
    }));
  };

 
  

  return (
    <div className="flex justify-items-center align-items-center">
      <div className="w-full max-w-[600px]">
        <Card className="w-full max-w-4xl mx-auto bg-primary-100">
          <CardContent className="p-6 !bg-primary-50">
            <img src={logo} alt="feed me syemore"  width="175" className="mt-6"/>
            <h3 className="text-sm font-semibold mb-6 text-secondary-700">Your AI Meal Plan Creator</h3>
            
            <DietSelector 
              selectedDiet={selectedDiet}
              onDietChange={handleDietChange}
              calories={calories}
              onCaloriesChange={handleCaloriesChange}
              onCheckboxChange={handleCheckboxChange}
              onGenerate={handleGenerate}
              errors={errors}
              isLoading={isLoading}
              isChecked ={isChecked}
            />
            
            {apiError && (
              <Alert severity="error">
                {apiError}
              </Alert>
            )}

            {isLoading && (
              <div className="flex flex-col items-center justify-center py-12">
                 <LinearProgress 
                  color='primary'
                  variant='determinate'
                  value={LoadProgress}
                  sx={{ 
                    width: '100%',
                    marginBottom: '8px',
                    '& .MuiLinearProgress-bar': {
                       backgroundColor: '#059669'
                    }
                  }}
                  />
                <div className="mt-4 text-primary-800">AI is generating your <span className="font-bold">{selectedDiet}</span> meal plan</div>  
                <div className="mt-2 text-primary-600">{progressMessages[progressMsg]}</div>
              </div>
              /*<div >
                <Loader2 className="h-8 w-8 animate-spin text-primary-600" />
                <p className="mt-4 text-primary-800">Generating your custom meal plan...</p>
              </div>*/
            )}
            
            {mealPlan && !isLoading && (
              
              <>
                <SummaryBox 
                  calories={calories}
                  protein={macros.protein}
                  carbs={macros.carbs}
                  fat={macros.fat}
                />
                <div className="grid grid-cols-2 gap-4 ">
                
                 <SendImageEmail
                  selectedDiet={selectedDiet}
                  calories={calories}
                  macros={macros}
                  mealPlan={mealPlan}
                  mealPlans = {fourDayPlan}
                  validDays = {validDays}
                />

                {mealPlan.map((meal, index) => (
                  <MealRow key={index} {...meal} />
                ))}


                
                
                </div>
                <div className="border-t border-primary-200 pt-8">
                  <NutrientCharts macros={macros} mealCalories={getMealCalories()} />
                 <SendImageEmail
                  selectedDiet={selectedDiet}
                  calories={calories}
                  macros={macros}
                  mealPlan={mealPlan}
                  mealPlans = {fourDayPlan}
                  validDays = {validDays}
                />
                </div>
              </>


            )}

            
          </CardContent>
        </Card>
      </div>
    </div>

  );
};

export default NutritionPlan;