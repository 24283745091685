import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import NutritionPlan from './components/NutritionPlan';
import { ThankYou } from './components/ThankYou';
import { TOS } from './components/tos.jsx'

import { BrowserRouter as Router, useLocation, Routes, Route } from 'react-router-dom';
import { initGA, initPixel, trackPageView, initExitIntentTracking, trackUserSource  } from './utils/analytics';


function App() {

  useEffect(() => {
    initGA('G-R3EN6X0S78'); 
    initPixel('1869457117131436');

    // Track initial page view
    trackPageView(window.location.pathname); //google
    trackPageView(); //meta
    
    // Initialize exit tracking
    initExitIntentTracking();
    
    // Track user source
    trackUserSource();
  }, []);

  return (
    <>
      <Helmet>
        <title>Feed Me, Seymore</title>
      </Helmet>
      <Router>
        <TrackRouteChanges>
          <Routes>
            <Route path="/" element={
              <div>
                <NutritionPlan />
              </div>
            }/>
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/terms-of-service" element={<TOS />} />
          </Routes>
        </TrackRouteChanges>
      </Router>
    </>
  );
}

// Tracks page views on route change
const TrackRouteChanges = ({children}) => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return children;
};


export default App;